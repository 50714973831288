.slide-container{
  position: relative;
  background: black;
}

.main-banner{
  /* background: red; */
  background: linear-gradient(transparent,#0000005c);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 90px 0px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.main-banner i{
  height: 15px;
  margin: 0 4px;
  display: inline-block;
  width: 15px;
  border-radius: 50%; 
  border: 2px solid #eadedd;  
}

/* ==========  navigator arrow start  ================================================================================================ */
.main-banner-navigator{
  position: absolute;
  top: 40%;
  /* background: red; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.main-banner-navigator i img{
  width: 25px;
  cursor: pointer;
}

@media screen and (max-width:768px) {
  .main-banner-navigator i img{
    width: 20px; 
  }
}

.main-banner-navigator i{
  /* border-radius: 50%; */
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.181);
}

.main-banner-navigator i:hover{
  background-color: rgba(255, 255, 255, 0.415);
}
/* ==========  navigator arrow end  ================================================================================================ */