.page-welcome-banner{
    background-size: 100% 100%;
    aspect-ratio: 10/2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 23vh;
    text-transform: uppercase; 
}

.page-welcome-banner div h1{ 
    padding-top: 50px;
}

@media screen and (min-width: 768px) {
    .page-welcome-banner{ 
        height: 30vh; 
    }
}
@media screen and (min-width: 912px) {
    .page-welcome-banner{ 
        height: 40vh; 
    }
}