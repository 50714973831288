@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-weight: bold;
}

.container_div {
  margin: 0px auto;
  /* margin: 45px auto; */
  max-width: 1600px;
  padding: 50px 5vw;
}

.home_page_heading {
  padding: 10px;
  padding-bottom: 10px;
}

@media screen and (max-width: 12000px) {
  .container_div {
    margin: 0px auto;
    padding: 45px 5vw;
  }
}

@media screen and (max-width: 1000px) {
  .container_div {
    margin: 0 auto;
    padding: 40px 30px;
  }
}

@media screen and (max-width: 800px) {
  .container_div {
    margin: 0 auto;
    padding: 35px 20px;
  }
}

@media screen and (max-width: 600px) {
  .container_div {
    margin: 0px auto;
    padding: 30px 15px;
  }
}

@media screen and (max-width: 400px) {
  .container_div {
    margin: 0 auto;
    padding: 25px 10px;
  }
}
@media screen and (max-width: 360) {
  .container_div {
    margin: 0 auto;
    padding: 25px 10px;
  }
}

/* carousel slider start---------------------------------------- */
.carouselImage {
  width: 100%;
  height: 100%;
  aspect-ratio: 3/1.3;
  min-height: 90vh;
}

.slider-container {
  z-index: 0;
}
/* carousel slider ends---------------------------------------- */

/* h1,
h2,
h3,
h4,h5,h6 {
  font-weight: bold;
} */
h1 {
  font-family: "Anton", sans-serif !important;
  text-transform: uppercase;
}
h3 {
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
}

/* p styling start */
p {
  font-size: 1.2rem;
  text-align: justify;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
}
@media screen and (max-width: 768px) {
  p {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 500px) {
  p {
    font-size: 1rem;
  }
}
/* p styling ends */

li,
address {
  font-weight: normal;
}
r



/* --------------------------------- material - icons ------------------------------------------------------------ */
.material-icons {
  /* margin: 0px 10px; */
  margin-right: 10px;
  font-size: 20px;
}
/* --------------------------------- material - icons ------------------------------------------------------------ */

iframe {
  overflow: hidden;
}

.main-app-master-layout-transition {
  transition: 500ms;
}

.handle_image_size {
  width: 100vw;
  height: 100vh;
  text-align: center;
  background-color: white;
  /* display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: wrap; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.handle_image_size_percent {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center center;
  min-height: 40vh;
  border-radius: 10px;
}

.handle_image_size img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
