@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@600&display=swap");

#nav1-navbar-main{
 
  height: 0;
  position: sticky;
  top: 0;
  z-index: 2;
}
.nav1-navbar-main-conatiner{
  position: sticky;
  top: 0;
  z-index: 1;
  background: linear-gradient(180deg, #000000ba,#0000007d,#0000004f, #0000000f);
}
#nav1-navbar {
    /* background-color: rgb(64,64,64); */
    /* background-color: #000000; */
    padding: 5px;
    height: 60px;
    /* position: sticky; */
    position: relative;
    /* background: linear-gradient( 180deg, rgb(0 0 0 / 30%), rgb(0 0 0 / 8%), #00000000 ); */
    z-index: 1;
    top: 0px;
    width: 100%;
    max-width: 1650px;
    /* background-color: #00000026; */
    /* box-shadow: 0px 0px 10px -7px black; */
    margin: auto;
}

#nav1-nav .nav1-a1 span {
  font-size: 1.3em;
  text-transform: uppercase;
  /* margin: -3px; */
}

.Logo-menu-div {
  position: relative;
}

.hamburger-menu {
  position: absolute;
  right: 10px;
  top: 10px;
}

.main-navbar-logo { 
  display: block !important; 
  position: absolute;
  top: 0;
  /* width: 190px !important;  */
  height: 50px;
  left: 10px;
  display: block !important;
}



#nav1-nav div {
  background-size: 100% 100%;
}

#nav1-nav .nav1-a2 ul {
  display: flex;
  margin-bottom: 0;
}

#nav1-nav .nav1-a2 {
  position: absolute;
  /* right: 10vw; */
  right: 5vw;
  /* transition: 1s ease-in-out; */
}

.black-semi-transparent {
  transition: 500ms ease-in-out;
  background-color: #00000075;
}

nav {
  display: flex;
}

#nav1-nav .nav1-a2 ul li {
  list-style: none; 
  padding-top: 15px;
  font-size: 0.8em;
  color: #3c486b;
  cursor: pointer;
  font-weight: bolder;
  
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-stretch: 100%;
  font-variation-settings: normal;
  font-feature-settings: normal;
  text-transform: uppercase;
  text-decoration: none solid rgb(245, 130, 31);
  text-align: left;
  text-indent: 0px;
}
#nav1-nav .nav1-a2 ul li a{
  padding: 3px 7px;
  border-right: 1px solid white;
}
#nav1-nav .nav1-a2 ul li a:nth-last-child(){
  border: none;
}

@media screen and (min-width: 1400px) {
  #nav1-nav .nav1-a2 ul li {
    font-size: 1em;
  }
}

#nav1-nav .nav1-a2 ul li a {
  text-decoration: none;
  /* color: #3c486b; */
  color: rgb(255, 255, 255);
}
#nav1-nav .nav1-a2 ul li a:hover {
  /* color: #ff7676; */
  color: rgb(245, 130, 31);
}

#nav1-nav .nav1-a1 {
  font-size: 1.7em;
  /* color: #3c486b; */
  color: rgb(245, 130, 31);
  position: absolute;
  /* left: 10vw; */
  left: 5vw;
  cursor: pointer;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-stretch: 100%;
  font-variation-settings: normal;
  font-feature-settings: normal;
  text-transform: none;
  text-decoration: none solid rgba(0, 0, 0, 0.9);
  text-align: start;
  text-indent: 0px;
  font-size: 20px;
}

#nav1-nav .nav1-a1 img {
  display: none;
}

@media screen and (max-width: 1080px) {
  #nav1-navbar {
    padding: 5px;
    height: 60px;
    top: -1px;
  }

  #nav1-nav .nav1-a2 {
    position: unset;
  }

  #nav1-nav .nav1-a1 {
    position: unset;
  }

  #nav1-nav .nav1-a1 img {
    display: inline;
  }

  #nav1-nav .nav1-a2 ul {
    display: block;
  }

  #nav1-nav .nav1-a2 ul li a {
    color: rgb(245, 130, 31);
  }

  #nav1-nav .nav1-a1 {
    width: 100vw;
  }
  #nav1-nav .nav1-a1 img {
    /* width: 40px; */
    vertical-align: -webkit-baseline-middle;
  }
  #nav1-nav .nav1-a1 .hamburger-menu {
    background: rgba(255, 255, 255, 0.071);
    border-radius: 2px;
    width: 36px;
    height: 30px;
    padding-top: 6px;
  }

  #nav1-nav .nav1-a1 span {
    text-align: center;
    font-size: 35px;
    vertical-align: -webkit-baseline-middle;
    padding-left: 15px;
  }

  #nav1-nav .nav1-a2 {
    /* background-color: rgb(145,44,246); */
    position: absolute;
    top: 60px;
    left: 0;
    height: 100vh;
    width: 100vw;
  }

  #nav1-nav .nav1-a2 ul li {
    padding-top: 20px;
    text-align: center;
  }
  #nav1-nav .nav1-a2 ul li a {
    color: rgb(245, 130, 31);
    line-height: 25px;
    vertical-align: baseline;
    letter-spacing: normal;
    word-spacing: 0px;
    margin: 0px;
    padding: 0px;

    font-weight: 600;
    font-style: normal;
    font-variant: normal;
    font-kerning: auto;
    font-optical-sizing: auto;
    font-stretch: 100%;
    font-variation-settings: normal;
    font-feature-settings: normal;
    text-transform: uppercase;
    text-decoration: none solid rgb(245, 130, 33);
    text-align: left;
    text-indent: 0px;

    font-size: 16px;

    border-bottom: 1px solid #09090942;
    display: block;
    padding-left: 15px;
  }

  #nav1-nav .nav1-a2 ul {
    padding: 10px;
    min-height: 100vh;
    width: 100vw;
    /* background-color: rgb(25, 33, 51); */
    background-color: rgb(255 255 255);
  }

  .nav1-hidenav {
    margin-left: -100vw;
    transition: 0.8s;
  }

  .nav1-shownav {
    transition: 0.5s;
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  #nav1-nav .nav1-a1 span {
    /* text-align: center; */
    font-size: 30px;
    /* vertical-align: -webkit-baseline-middle; */
    /* padding-left: 15px; */
  }

  .main-navbar-logo{
    top: 5px;
    width: 160px !important;
  }
}
@media screen and (max-width: 450px) {
  #nav1-nav .nav1-a1 span {
    /* text-align: center; */
    font-size: 25px;
    /* vertical-align: -webkit-baseline-middle; */
    /* padding-left: 15px; */
  }
}
