.journey_panel_journey_img{
    width: 100%;
}

.journey_vertical_timeline_element .vertical-timeline-element-content{
    background: none !important;
    border: 1px solid red;
    color: #3c486b !important;
}

.journey_vertical_timeline_element .vertical-timeline-element-content div{
    border-right: 7px solid rgb(255, 0, 0) !important;
}

.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    color: #3c486b !important;
}