.app-card {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas: " app_sec_2 app_sec_1 ";
}

.app-card .app-image img {
  width: 100%;
  height: 100%;
}
.app-card .app-description div {
  /* width: 80%;
    height: 80%; */
}
.app-card-sec1 {
  grid-area: app_sec_1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-card-sec2 {
  grid-area: app_sec_2;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-card-sec2 button {
  border: 2px solid #3c486b;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px 0;
  margin: 0px 10px;
  background-color: #3c486b;
  min-width: 165px;
}

.app-card-sec2 button .material-icons {
  font-size: 25px;
}

.app-card-sec2 h3 {
  color: rgb(60, 72, 107);
  font-size: 56px;
  font-weight: 600;
  line-height: 68px;
  vertical-align: baseline;
  letter-spacing: -0.8px;
  word-spacing: 0px;
  margin: 20px 0px 10px;
  padding: 0px;
  text-align: left !important;
}

@media screen and (max-width: 912px) {
  .app-card {
    display: grid;
    grid-template-columns: 100% 100%;
    grid-template-areas: "app_sec_2" "app_sec_1";
  }

  .app-card .app-image img {
    width: 80%;
    height: 80%;
  }

  .app-card .app-description div {
    width: 80%;
    height: 100%;
  }

  .app-card-sec2 h3 {
    font-size: 35px;
  }

  .app-card-sec2 button {
    padding: 5px 0;
    margin: 5px;
  }
}

.black1 {
  background-color: #242424 !important;
}
.black2 {
  background-color: #121212 !important;
}

.color1 {
  color: #bfc3ea !important;
}
.color2 {
  color: #b03745 !important;
}
