#single-blog {
  display: grid;
  gap: 10;
  grid-template-areas: "single_blog1 single_blog2";
  /* margin: 30px; */
  padding: 10px;
}

@media screen and (min-width: 912px) {
  #single-blog {
    grid-template-columns: 50% 50%;
  }
}
@media screen and (max-width: 912px) {
  #single-blog {
    grid-template-areas:
      "single_blog1"
      "single_blog2";
  }
}

#single-blog .div-1 {
  grid-area: "single_blog1";
}
#single-blog .div-1 img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  aspect-ratio: 3/2;
}
#single-blog .div-2 {
  grid-area: "single_blog2";
  padding: 20px;
}

#single-blog .div-2 .blogger-div {
  display: flex;
  /* padding: 10px; */
  /* background: red; */
  align-items: center;
  justify-content: center;
}
#single-blog .div-2 .blogger-div img {
  width: 57px;
  border-radius: 77px;
  margin: 0px 15px;
}
#single-blog .div-2 .blogger-div h4 {
  justify-content: left;
  align-items: center;
  display: flex;
  margin: 0;
}

#single-blog .div-2 .single-blog-data h3 {
  color: rgb(60, 72, 107);
  font-weight: 600;
  text-align: center; 
  margin-top: 20px;
}
#single-blog .div-2 .single-blog-data p {
  color: rgb(60, 72, 107);
  font-weight: 600;
  text-align: justify;
  padding: 20px;
}
