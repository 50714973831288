.home_page_heading h3 {
  color: #ff6969;
  text-align: center;
}
.home_page_heading h1 {
  color: #3c486b;
  text-align: center;
}
@media screen and (max-width: 400px) {
  .home_page_heading h3 {
    color: #ff6969;
  }
  .home_page_heading h1 {
    color: #3c486b;
  }
}

/* ----------------------------------------------------- */
#blogpage-card-view-box-1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  /* justify-content: space-between; */
}



#blogpage-card-view-box-1 .card-box {
  
  width: 350px;  
  background: #ffffff;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  /* box-shadow: 0px 2px 6px -3px black; */
}

@media screen and (min-width:1080px) {
  #blogpage-card-view-box-1 .card-box {
    width: 315px;
    height: 80vh;
  }
}

.blobdetail_box{
  padding: 15px;
}

#blogpage-card-view-box-1 .card-box img { 
  width: 100%;
  aspect-ratio: 3/2;
}

#blogpage-card-view-box-1 .card-box h4 {
  margin: 12px 0;
  color: #9e9e9e;
}

#blogpage-card-view-box-1 .card-box h3 {
  margin: 12px 0;
}

#blogpage-card-view-box-1 .card-box p {
  color: #3c486b;
}

#blogpage-card-view-box-1 .card-box .blogger_details{
  display: flex; 
  align-items: center;
}

#blogpage-card-view-box-1 .card-box .blogger_details img{
    width: 10%;
    aspect-ratio: 1/1;
    border-radius: 50%;
}

.read_more_blog{
  border: 2px solid #e0e0e0;
  font-size: 15px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: inline-block;
  padding: 10px 30px;
  transition: 0.3s ease; 
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  color: rgb(60, 72, 107);
}


@media screen and (max-width: 768px) {
  #blogpage-card-view-box-1 .card-box {
    width: 46%;
    padding: 15px;
  }

  #blogpage-card-view-box-1 .card-box h4 {
    margin: 12px 0;
    color: #9e9e9e;
  }

  #blogpage-card-view-box-1 .card-box h3 {
    margin: 12px 0;
  }

  #blogpage-card-view-box-1 .card-box p {
    color: #3c486b;
  }


}


@media screen and (max-width: 600px) {
  #blogpage-card-view-box-1 .card-box {
    width: 100%;
    padding: 15px;
  }
}

/* ----------------------------------------------------- */
