.onlineDarbar_div1 {
  display: grid;
  grid-template-columns: 40% 60% !important;
  background: white;
  padding: 30px 0px;
}
.onlineDarbar_div1 .grid-section1 {
  display: flex;
  justify-content: center;
}

.onlineDarbarMainForm {
  border-radius: 20px;
  overflow: hidden;
}

.online-darbar-form {
  width: 96%;
  padding: 7px 30px;
  border: none;
  /* box-shadow: 0px 1px 3px -1px #3a3838; */
  margin: 10px 0;
  color: #747474;
  margin: 10px 2%;
  border-radius: 77px;
  background: #f3f3f3;
  font-family: "Open Sans", sans-serif;

  font-weight: 500;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-stretch: 100%;
  font-variation-settings: normal;
  font-feature-settings: normal;
  text-indent: 0px;
  /* max-width: 500px; */
}

.online-darbar-form:focus {
  outline: none !important;
  /* border:1px solid #dddddd; 
        box-shadow: 0px 2px 4px -1px #3a3838; */
}

form .half-input {
  width: 46%;
  margin: 10px 2%;
}

@media screen and (max-width: 768px) {
  form .half-input {
    width: 96%;
    margin: 10px 2%;
  }
}

form button {
  margin: 28px 0;
  padding: 8px 25px;
  border: none;
  background-color: #3c486b;
  color: white;
  width: 96%;
  margin: 10px 2%;
  border-radius: 50px;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #b4b4b3;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b4b4b3;
}

::placeholder {
  color: #b4b4b3;
}

body {
  background-color: #f3f3f3;
}

.privacy-policy {
  margin: 5px 15px;
}
.privacy-policy label {
  margin-left: 10px;
}

.privacy-policy label .highlight {
  color: red;
  cursor: pointer;
}


.black1{
  background-color: #242424 !important;
}
.black2{
  background-color: #121212 !important;
}

.color1{
  color: #bfc3ea !important;
}
.color2{
  color: #b03745 !important;
}