
.maingv .slide_section{
    width: 100vw;
    overflow: hidden;
}

.maingv .slide_section .sliderImages {
  display: flex; 
  transition: 500ms;
}
.sliderImages img, .sliderImages div {
  min-width: 100vw;
  height: 100vh;
}

.maingv {
  position: relative;
  overflow: hidden;
}

/* -------------------- slider arrows box  ------------------------------------- */

.maingv .sliderArrows {
  /* background-color: red; */
  position: absolute;
  top: 40%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.maingv .sliderArrows span {
  background: #ffffff7a;
  padding: 3px;
}
.maingv .sliderArrows img {
  width: 25px;
  cursor: pointer;

}

/* -------------------- slider arrows box  ------------------------------------- */

/* -------------------- slider message box  ------------------------------------- */
.maingv .sliderdata {
  position: absolute;
  width: 100%;
  background: #00000096;
  text-align: center;
  bottom: 0;
}

.maingv .sliderdata .slidergv_title {
}
.maingv .sliderdata .slidergv_description {
}
/* -------------------- slider message box  ------------------------------------- */

.maingv .sliderdata .slidergv_title {
  text-align: center;
  font-family: "Anton", sans-serif;
  font-size: 40px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  font-style: normal;
  line-height: 40px;
  font-weight: 500;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-stretch: 100%;
  font-variation-settings: normal;
  font-feature-settings: normal;
  text-transform: uppercase;
  text-decoration: none solid rgb(0, 0, 0); 
  text-indent: 0px;
  margin-bottom: 25px;
  margin-top: 10px;
}

.maingv .sliderdata .slidergv_description {
  font-family: "Open Sans", sans-serif;
  font-family: "open sans" sans-serif;
  font-size: 23px;
  line-height: 33px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  margin: 0px 0px 8px;
  padding: 0px;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-style: 700;
  font-variant: normal;
  font-kerning: normal;
  font-optical-sizing: auto;
  font-stretch: auto;
  font-variation-settings: 100%;
  font-feature-settings: normal;
  text-transform: normal;
  text-decoration: none;
  text-align: none solid rgb(26, 24, 40);
  text-indent: start;
  margin-bottom: 10px;
  color: #ffffff;
  color: RGB(255, 255, 255);
  background-color: #000000;
  background-color: RGBA(0, 0, 0, 0);
}

@media screen and (max-width: 1080px) {
  .maingv .sliderdata .slidergv_title {
    font-size: 30px;
    line-height: 30px;
  }
  .maingv .sliderdata .slidergv_description {
    font-size: 20px;
    line-height: 28px;
  }
}

@media screen and (max-width: 580px) {
  .maingv .sliderdata .slidergv_title {
    font-size: 25px;
    line-height: 26px;
  }
  .maingv .sliderdata .slidergv_description {
    font-size: 16px;
    line-height: 22px;
  }
}
