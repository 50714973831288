/* =============>footer styling Starts */
#myFooter-1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
  background-color: #3c486b;
  color: #f0f0f0;
}

#myFooter-1 div {
  min-height: 100px;
  min-width: 200px;
  margin-top: 10px;
  /* border: 2px solid white; */
  /* padding: 20px; */
  padding-left: 40px;
}

#myFooter-1 div span {
  width: 250px;
  text-align: justify;
  font-weight: normal;
  font-size: 0.9rem;
  color: white;
  display: block;
}
#myFooter-1 div button {
  margin: 28px 0;
  padding: 8px 25px;
  border: none;
  color: #3c486b;
  background-color: white;
  border-radius: 50px;
}

#myFooter-1 div ul li a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 0.8rem;
  font-weight: normal;
  text-transform: uppercase;
}

#myFooter-1 div ul li {
  list-style: none;
  font-weight: normal;
  margin-top: 5px;
}
#myFooter-1 div ul{
  padding-left: 0;
}

.copyright_message {
  /* text-align: center; */
  background-color: rgb(43, 48, 53);
  color: rgb(130, 140, 153);
  padding: 10px; 
}
.copyright_message {
  text-align: center !important;
  font-size: 1rem !important;
}

@media screen and (max-width: 768px) {
  #myFooter-1 div {
    min-height: 100px;
    width: 90vw;
  }
  #myFooter-1 div span {
    width: 80%;
  }
}

@media screen and (max-width: 1080px) {
  #myFooter-1 div {
    min-height: 100px;
    min-width: 35vw;
  }
}

.footerh5 {
 /* color: #c25e6a */
 color: #ffffff;
 font-weight: 600;
}

 
/* =============>footer styling end */
 