.grid-section1 {
  grid-area: "grid-section1";
}
.grid-section1 {
  grid-area: "grid-section1";
}

#news_div1 {
  display: grid;
  grid-template-columns: 50% 50%; 
  grid-template-rows: auto;
  grid-template-areas: "grid-section1 grid-section1"; 
  /* padding: 10px; */
  margin: 10px 0;
  background-color: white;
  /* max-width: 1200px; */
  border-radius: 20px;
  overflow: hidden;
  margin: 10px auto;
  /* box-shadow: 0px 2px 7px -4px black;  */
}

#news_div1 .grid-section1 img {  
  width: 100%;
  aspect-ratio: 4/2;
  height: 100%;
}

#news_div1 .grid-section2 {
  padding: 2% 5%;
} 
#news_div1 .grid-section2 h1 {
  color: #3c486b;
  font-size: 1.5rem;
  font-family: "Open Sans", sans-serif !important; 
  font-weight: 600;
}
#news_div1 .grid-section2 p {
  color: #3c486b;
  font-size: 1.3rem;
  text-align: justify;
}

@media screen and (max-width: 400px) { 
  #news_div1 .grid-section2 h1 {
    color: #3c486b;
    font-size: 1.2rem;
  }
  #news_div1 .grid-section2 p {
    color: #3c486b;
    font-size: 1rem;
    text-align: justify;
  }
}

#news_div1 .grid-section2 button {
  margin: 28px 0;
  padding: 8px 25px;
  border: none;
  background-color: #ff6969;
  color: white;
  border-radius: 50px;
}

#news_div1 .grid-section2 button:hover {
  background-color: #3c486b;
}
 
@media screen and (max-width: 912px) {
  #news_div1 {
    grid-template-columns: 100%;
    grid-template-areas: "grid-section1" "grid-section1";
    padding: 10px;
    text-align: center;
    max-width: 600px;
  }

  #news_div1 .grid-section1 img {
     /* border: 20px solid #ff6969; */
    /* margin: 0 5%; */
    width: 100%;
    /* max-width: 400px; */
    align-self: center;
  }

  #news_div1 .grid-section2 p {
    font-size: 1rem;
  }
}
