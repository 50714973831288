@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
  @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap');

.governance-message-box {
}

.main-governance-navigator {
  position: absolute;
  bottom: 10%;
  right: 10%;
  background: white;
  width: 100px;
  display: flex;
  border-radius: 50px 50px 50px 50px;
  justify-content: space-between;
  padding: 8px;
}

.main-governance-navigator i img {
  width: 25px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .main-governance-navigator i img {
    width: 20px;
  }
}

.main-governance-navigator i {
  border-radius: 50%;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.181);
}

.main-governance-navigator i:hover {
  background-color: rgba(255, 255, 255, 0.415);
}

/* =========================== issue message box styling ================================================================ */

.governance-message {
  position: relative;
}

.governance-message-box {
  position: absolute;
  top: 55%;
  left: 10%;
  /* background: red; */
  min-height: 10px;
  width: 80%;
}

.governance-message-box .governance-title {
  font-family: 'Oswald', sans-serif;
  font-size: 80px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  font-style: normal;
  line-height: 88px;
  font-weight: 500;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-stretch: 100%;
  font-variation-settings: normal;
  font-feature-settings: normal;
  text-transform: uppercase;
  text-decoration: none solid rgb(0, 0, 0);
  text-align: start;
  text-indent: 0px;
  margin-bottom: 25px;
  text-align: center;
  
  text-shadow: 0px 0px 2px #00000057;

}
.governance-message-box .governance-description {
  font-family: "open sans" sans-serif;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-stretch: 100%;
  font-variation-settings: normal;
  font-feature-settings: normal;
  text-transform: none;
  text-decoration: none solid rgb(255, 255, 255);
  text-align: center;
  text-indent: 0px;

  line-height:24px;
  vertical-align:baseline;
  letter-spacing:normal;
  word-spacing:0px;
  margin:0px;
  padding:0px;

  color:#FFFFFF;
  color:RGB(255, 255, 255);
  background-color:#000000;
  background-color:RGBA(0, 0, 0, 0);
  
  text-shadow: 0px 0px 5px #00000057;

}

.slide-container .governance-read-btn {
  text-align: center;
  position: absolute;
  bottom: 5%;
  width: 100%;
  display: flex;
  padding: 0 5%;
  justify-content: space-between;
  align-items: center;
}
.slide-container .governance-read-btn .section_description{
  font-family: "Anton", sans-serif;
  font-size: 75px;
  color: white;
  font-weight:500;
  font-style:normal;
  font-variant:normal;
  font-kerning:auto;
  font-optical-sizing:auto;
  font-stretch:100%;
  font-variation-settings:normal;
  font-feature-settings:normal;
  text-transform:uppercase;
  text-decoration:none solid rgb(255, 255, 255);
  text-align:start;
  text-indent:0px;

  line-height:82.5px;
  vertical-align:baseline;
  letter-spacing:normal;
  word-spacing:0px; 
  padding:0px;
  
  text-shadow: 0px 0px 10px #00000057;

}
.slide-container .governance-read-btn button { 
  color: #000;
  display: inline-block;
  border: 1px solid white;
  padding: 16px 40px;
  margin-top: 20px;
  color: white;
  background-color: #000000;
  background-color: RGBA(0, 0, 0, 0);

  line-height: 25.5px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px; 
  padding: 8px 20px;

  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-stretch: 100%;
  font-variation-settings: normal;
  font-feature-settings: normal;
  text-transform: none;
  text-decoration: none solid rgb(0, 0, 0);
  text-align: start;
  text-indent: 0px;

  font-size: 30px;
  line-height: 26px;
  font-family: "Inter", sans-serif;
}

.governance-message-box .governance-title {
  font-size: 30px;
  line-height: 31px;
  color: white;
  font-family: 'Oswald', sans-serif;
}
.governance-message-box .governance-description {
  font-size: 17px;
  line-height: 22px;
  color: white;
}
.governance-message-box .governance-read-btn button {
  font-size: 17px;
  line-height: 26px;
  color: white;
}



@media screen and (max-width: 1080px) {
  .slide-container .governance-read-btn .section_description{
    font-size: 38px;
    line-height: 41px;
  } 
  .slide-container .governance-read-btn button {
    font-size: 25px;
    line-height: 22px;
  }
}

@media screen and (max-width: 580px) {
  .slide-container .governance-read-btn .section_description{
    font-size: 24px;
    line-height: 26px; 
    margin: 0;
  } 
  .slide-container .governance-read-btn button {
    font-size: 16px;
    line-height: 15px; 
    margin: 0;
  }
}
/* =========================== issue message box styling ================================================================ */

/* =========================== image management -start ===================================================== */
.governance-image-size {
  height: 100vh;
  width: 25vw;
}
@media screen and (max-width: 1400px) {
  .governance-image-size {
    width: 33.33333vw;
  }
}
@media screen and (max-width: 1050px) {
  .governance-image-size {
    width: 50vw;
  }
}
@media screen and (max-width: 700px) {
  .governance-image-size {
    width: 100vw;
  }
}
/* =========================== image management -start ===================================================== */
