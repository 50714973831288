@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.journey-message-box {
}

.main-journey-navigator {
  position: absolute;
  bottom: 10%;
  right: 10%;
  background: white;
  width: 100px;
  display: flex;
  border-radius: 50px 50px 50px 50px;
  justify-content: space-between;
  padding: 8px;
}

.main-journey-navigator i img {
  width: 25px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .main-journey-navigator i img {
    width: 20px;
  }
}

.main-journey-navigator i {
  border-radius: 50%;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.181);
}

.main-journey-navigator i:hover {
  background-color: rgba(255, 255, 255, 0.415);
}

/* =========================== journey message box styling ================================================================ */

.journey-message {
  position: relative;
  width: 100vw;
}

.journey-message-box {
  position: absolute;
  top: 20%;
  left: 10%;
  /* background: red; */
  min-height: 10px;
  width: 35vw;
}

.journey-message-box .journey-title {
  font-family: "Anton", sans-serif;
  font-size: 80px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  font-style: normal;
  line-height: 88px;
  font-weight: 500;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-stretch: 100%;
  font-variation-settings: normal;
  font-feature-settings: normal;
  text-transform: uppercase;
  text-decoration: none solid rgb(0, 0, 0);
  text-align: start;
  text-indent: 0px;
  margin-bottom: 25px;
}
.journey-message-box .journey-timeline {
  font-family: "Open Sans", sans-serif;
  font-family: "open sans" sans-serif;
  font-size: 45px;
  line-height: 33px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  margin: 0px 0px 8px;
  padding: 0px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-style: 700;
  font-variant: normal;
  font-kerning: normal;
  font-optical-sizing: auto;
  font-stretch: auto;
  font-variation-settings: 100%;
  font-feature-settings: normal;
  text-transform: normal;
  text-decoration: none;
  text-align: none solid rgb(26, 24, 40);
  text-indent: start;
  margin-bottom: 10px;


  color:#FFFFFF;
  color:RGB(255, 255, 255);
  background-color:#000000;
  background-color:RGBA(0, 0, 0, 0);
}
.journey-message-box .journey-description {
  font-family: "Open Sans", sans-serif;
  font-family: "open sans" sans-serif;
  font-size: 23px;
  line-height: 33px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  margin: 0px 0px 8px;
  padding: 0px;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-style: 700;
  font-variant: normal;
  font-kerning: normal;
  font-optical-sizing: auto;
  font-stretch: auto;
  font-variation-settings: 100%;
  font-feature-settings: normal;
  text-transform: normal;
  text-decoration: none;
  text-align: none solid rgb(26, 24, 40);
  text-indent: start;
  margin-bottom: 10px;
  color:#FFFFFF;
  color:RGB(255, 255, 255);
  background-color:#000000;
  background-color:RGBA(0, 0, 0, 0);
}

.journey-message-box .journey-read-btn {
}
.journey-message-box .journey-read-btn button {
  font-size: 17px;
  color: #000;
  display: inline-block;
  border: 1px solid #ffffff;
  padding: 8px 20px;
  margin-top: 20px;
  color: RGB(0, 0, 0);
  background-color: #000000;
  background-color: RGBA(0, 0, 0, 0);

  line-height: 25.5px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  margin: 20px 0px 0px;
  padding: 8px 20px;

  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-stretch: 100%;
  font-variation-settings: normal;
  font-feature-settings: normal;
  text-transform: none;
  text-decoration: none solid rgb(0, 0, 0);
  text-align: start;
  text-indent: 0px;

  font-size: 17px;
  line-height: 26px;
  font-family: "Inter", sans-serif;
  color:#FFFFFF;
  color:RGB(255, 255, 255);
  background-color:#000000;
  background-color:RGBA(0, 0, 0, 0);
}

@media screen and (max-width: 1080px) {
  .journey-message-box .journey-title {
    font-size: 38px;
    line-height: 41px;
  }
  .journey-message-box .journey-description {
    font-size: 20px;
    line-height: 28px;
  }
  .journey-message-box .journey-read-btn button {
    font-size: 20px;
    line-height: 26px;
  }
  .journey-message-box .journey-timeline {
    font-size: 30px;
    line-height: 20px;
  }
}

@media screen and (max-width: 580px) {
  .journey-message-box .journey-title {
    font-size: 28px;
    line-height: 31px;
  }
  .journey-message-box .journey-description {
    font-size: 16px;
    line-height: 22px;
  }
  .journey-message-box .journey-read-btn button {
    font-size: 17px;
    line-height: 26px;
  }
  .journey-message-box .journey-timeline {
    font-size: 22px;
    line-height: 20px;
  }
}
/* =========================== journey message box styling ================================================================ */


/* =========================== journey main-journye-timeline-nav styling ================================================================ */
.main-journye-timeline-nav{
    position: absolute;
    bottom: 0%;
    left: 5%;
    min-height: 56px;
    width: 90%; 
    background: rgba(255, 255, 255, 0); 
    display: flex; 
    justify-content: space-between; 
    overflow-x: scroll;
}
.main-journye-timeline-nav .timeline-span{
    font-family: "open sans" sans-serif;
    color:RGB(221, 221, 221);
    font-size: 22px;
    line-height:33px;
    vertical-align:baseline;
    letter-spacing:normal;
    word-spacing:0px;
    margin:0px;
    padding:0px;

    font-weight:400;
    font-style:normal;
    font-variant:normal;
    font-kerning:auto;
    font-optical-sizing:auto;
    font-stretch:100%;
    font-variation-settings:normal;
    font-feature-settings:normal;
    text-transform:none;
    text-decoration:none solid rgb(221, 221, 221);
    text-align:center;
    text-indent:0px;
    border-right: 1px solid rgb(221, 221, 221);
    padding: 5px 20px;
    min-width: 200px;
    cursor: pointer;
}

.main-journye-timeline-nav .timeline-span:nth-of-type(){
    border-right: none;
}
/* =========================== journey main-journye-timeline-nav styling ================================================================ */

/* =========================== journey scrolbar styling ================================================================ */
/* width */
/* ::-webkit-scrollbar {
    width: 5px;
  } */
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #c1c1c100;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ffffff2b;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
/* =========================== journey scrolbar styling ================================================================ */

