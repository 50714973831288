.react-paginate-navigator{
    background-color: #f0f0f0;
    display: flex;
    list-style: none;
    justify-content: center;
}

.react-paginate-navigator li{
    background: white; 
    border: 1px solid #9c9696;
    margin: 5px;
    border-radius: 5px;
    
}
.react-paginate-navigator li a{
    text-decoration: none;
    margin: 5px;
    padding: 5px;
    color: #3c486b;
}