.galley_image{
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: center;
    flex-direction: row;
    justify-content: center;
}

.galley_image div{ 
    width: 350px;
    aspect-ratio: 5/3;
    /* border: 4px solid #ff6969; */
    margin: 10px;
    border-radius: 5px;
    overflow: hidden;
}
.galley_image div img{
    height: 100%;
}