.social-media-container{
    /* background-color: red; */
    height: 100vh;
    position: relative;
    /* display: none; */
}
.social-media-container .social-media-heading{
    height: 15vh;
}

.social-media-frames{
     height: 80vh;
}

.social-media-frames iframe{
    width: 100%;
    height: 100%;
}

.socialMediaNav-div{
    overflow-x: scroll;
}

.socialMediaNav{
    height: 5vh;
    display: flex;
}
.socialMediaNav .socialMediaNav-item{
  
    height: 100%;
    background: #6d575729;
    color: #717a92;
    min-width: 250px;
    font-size: 20px;
    border: 1px solid white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; 
    width: 100%;
    border-radius: 50px;


}